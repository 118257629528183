import { getModelStates, createFullPath } from 'mid-addin-lib';
import { useState, useCallback, useEffect } from 'react';
import { productDefinitionActions, useProductDefinitionStore } from '../../../../context/DataStore/productDefinitionStore';
import { useShallow } from 'zustand/react/shallow';

interface UseGetAvailableRepresentationsState {
  availableRepresentations: string[];
}

export const useGetAvailableRepresentations = (): UseGetAvailableRepresentationsState => {
  const { topLevelFolder, assembly } = useProductDefinitionStore(
    useShallow((state) => ({
      topLevelFolder: state.topLevelFolder,
      assembly: state.assembly,
    })),
  );

  const [availableRepresentations, setAvailableRepresentations] = useState<string[]>([]);

  const fetchModelStates = useCallback(async (iamFileFullPath: string) => {
    const modelStates = await getModelStates(iamFileFullPath);
    setAvailableRepresentations(modelStates);

    // After get available model states from Inventor model, we might need to update those options
    // in the productDefinition to remove some model states that are no longer available.
    productDefinitionActions.updateAvailableOutputOptions(modelStates);
  }, []);

  useEffect(() => {
    if ((topLevelFolder && assembly) || import.meta.env.VITE_IS_FUSION) {
      fetchModelStates(createFullPath(topLevelFolder, assembly));
    }
  }, [assembly, topLevelFolder, fetchModelStates]);

  return { availableRepresentations };
};
