/* eslint-disable new-cap */
import { BrowserApi, BrowserApiServiceResult } from '../interfaces/browserApi';
import { HostApi, Result } from '../interfaces/hostApi';
import { Base64Info, ReadFromFileUsingDialogResult, SaveToFileUsingDialogResult } from '../interfaces/fileSystem';
import { GenerateOutputsResult } from '../interfaces/inventorAutomation';
import { InventorProperties } from '../interfaces/inventorProperties';
import { CachedFileInfo } from '../interfaces/localCache';
import { Environment } from 'mid-types';
import { LogLevel } from '../interfaces/log';
import { InventorActiveDocumentInfo } from '../interfaces/inventorActiveDocumentInfo';
import { RevitClassification } from '../interfaces/revitClassification';
import { PublishChecks } from '../interfaces/publishChecks';

declare global {
  interface Window {
    chrome: {
      webview: {
        hostObjects: {
          hostApi: HostApi;
        };
      };
    };
    adsk: {
      fusionSendData: (action: string, data?: string) => Promise<any>;
    };
  }
}

class BrowserApiService implements Partial<BrowserApi> {
  /**
   * Converts a webView2 proxy result into a TypeScript object.
   */
  getValueFromProxy(value: any, proxy: any): any {
    for (const property in value) {
      value[property] = proxy[property];
    }
    return value;
  }

  // We replaced this with auth component for fusion
  async getOAuth2Token(): Promise<string | null> {
    return null;
  }

  async loadProductDefinitions(): Promise<BrowserApiServiceResult<string>> {
    const result: Result = JSON.parse(await window.adsk.fusionSendData('LoadProductDefinitions'));
    return { value: result.content, errorMessage: result.errorMessage };
  }

  async saveProductDefinitions(productDefinitions: string): Promise<BrowserApiServiceResult<boolean>> {
    const result = JSON.parse(await window.adsk.fusionSendData('SaveProductDefinitions', productDefinitions));
    return { value: result.content, errorMessage: result.errorMessage };
  }

  async getActiveDocumentInfo(): Promise<BrowserApiServiceResult<InventorActiveDocumentInfo>> {
    const result: Result = JSON.parse(await window.adsk.fusionSendData('GetActiveDocumentInfo'));
    if (result.errorMessage) {
      return { value: null, errorMessage: result.errorMessage };
    }
    const inventorActiveDocumentInfo: InventorActiveDocumentInfo = { location: '', name: '' };
    this.getValueFromProxy(inventorActiveDocumentInfo, result.content);
    return { value: inventorActiveDocumentInfo, errorMessage: result.errorMessage };
  }

  async getThumbnailImage(documentPath: string): Promise<BrowserApiServiceResult<string>> {
    const result: Result = JSON.parse(await window.adsk.fusionSendData('GetThumbnailImage', documentPath));
    return { value: result.content, errorMessage: result.errorMessage };
  }

  async getPartOrAssemblyProperties(path: string): Promise<BrowserApiServiceResult<InventorProperties>> {
    const result: Result = JSON.parse(await window.adsk.fusionSendData('GetPartOrAssemblyProperties', path));
    if (result.errorMessage) {
      return { value: null, errorMessage: result.errorMessage };
    }
    return { value: result.content, errorMessage: result.errorMessage };
  }

  async getEnvironment(): Promise<Environment> {
    // return await window.adsk.fusionSendData('GetEnvironment');
    return 'stg';
  }

  async getModelStates(documentPath: string): Promise<BrowserApiServiceResult<string[]>> {
    const result: Result = JSON.parse(await window.adsk.fusionSendData('GetModelStates', documentPath));
    return { value: result.content, errorMessage: result.errorMessage };
  }

  async getDcApiUrl(): Promise<string> {
    // return await window.adsk.fusionSendData('GetDcApiUrl');
    return 'https://api.stg.dc.autodesk.com/v1/';
  }

  async getMIDWebAppUrl(): Promise<string> {
    return await window.adsk.fusionSendData('GetMIDWebAppUrl');
  }

  async fileToBase64String(filePath: string): Promise<BrowserApiServiceResult<Base64Info>> {
    const result: Result = JSON.parse(await window.adsk.fusionSendData('FileToBase64String', filePath));
    if (result.errorMessage) {
      return { value: null, errorMessage: result.errorMessage };
    }
    let base64: Base64Info = { name: '', base64: '' };
    base64 = this.getValueFromProxy(base64, result.content);
    return { value: base64, errorMessage: result.errorMessage };
  }

  async compressFolder(folderPath: string): Promise<BrowserApiServiceResult<string>> {
    const result: Result = JSON.parse(await window.adsk.fusionSendData('CompressFolder', folderPath));
    return { value: result.content, errorMessage: result.errorMessage };
  }

  async generateOutputs(
    topFolderPath: string,
    documentPath: string,
    inputs: string,
    requestedOutputs: string,
  ): Promise<GenerateOutputsResult> {
    const result = JSON.parse(
      await window.adsk.fusionSendData(
        'CompressFolder',
        JSON.stringify({
          topFolderPath,
          documentPath,
          inputs,
          requestedOutputs,
        }),
      ),
    );
    const outputsResult: GenerateOutputsResult = { success: result.success, report: result.report };
    if (result.outputFiles) {
      outputsResult.outputFiles = [];
      for (const proxy of result.outputFiles) {
        outputsResult.outputFiles.push(this.getValueFromProxy({ type: '', modelState: '', filePath: '' }, proxy));
      }
    }
    return outputsResult;
  }

  async getAssemblyVersion(): Promise<string> {
    return await window.adsk.fusionSendData('GetAssemblyVersion');
  }

  async openExternalUrl(url: string): Promise<BrowserApiServiceResult<boolean>> {
    const result: Result = JSON.parse(await window.adsk.fusionSendData('OpenExternalUrl', url));
    return { value: result.content, errorMessage: result.errorMessage };
  }

  async deleteFile(filePath: string): Promise<BrowserApiServiceResult<boolean>> {
    const result: Result = JSON.parse(await window.adsk.fusionSendData('DeleteFile', filePath));
    return { value: result.content, errorMessage: result.errorMessage };
  }

  async checkAndGenerateThumbnailInBase64(
    filePath: string,
    documentPath: string,
  ): Promise<BrowserApiServiceResult<Base64Info>> {
    const result: Result = JSON.parse(
      await window.adsk.fusionSendData('CheckAndGenerateThumbnailInBase64', JSON.stringify({ filePath, documentPath })),
    );
    if (result.errorMessage) {
      return { value: null, errorMessage: result.errorMessage };
    }
    let base64: Base64Info = { name: '', base64: '' };
    base64 = this.getValueFromProxy(base64, result.content);
    return { value: base64, errorMessage: result.errorMessage };
  }

  async getApplicationVersionNumber(): Promise<string> {
    return await window.adsk.fusionSendData('GetApplicationVersionNumber');
  }

  async saveToFile(content: string, fileName: string, fileExtension: string): Promise<BrowserApiServiceResult<string>> {
    const result: Result = await window.adsk.fusionSendData(
      'SaveToFile',
      JSON.stringify({ content, fileName, fileExtension }),
    );
    return { value: result.content, errorMessage: result.errorMessage };
  }

  async getRevitClassification(): Promise<BrowserApiServiceResult<RevitClassification>> {
    const result: Result = JSON.parse(await window.adsk.fusionSendData('GetRevitClassification'));

    if (result.errorMessage) {
      return { value: null, errorMessage: result.errorMessage };
    }
    const revitClassification: RevitClassification = {
      behavior: '',
      category: '',
      familyName: '',
      familyTypeName: '',
      isValid: false,
    };
    this.getValueFromProxy(revitClassification, result.content);
    return { value: revitClassification, errorMessage: result.errorMessage };
  }

  // openProductDefinitionDocument and isDocumentOpenInTheEditor are used to set model inputs

  async openProductDefinitionDocument(documentPath: string, inputs: string): Promise<BrowserApiServiceResult<boolean>> {
    const result: Result = await window.adsk.fusionSendData(
      'OpenProductDefinitionDocument',
      JSON.stringify({ documentPath, inputs }),
    );
    return { value: result.content, errorMessage: result.errorMessage };
  }
  async isDocumentOpenInTheEditor(documentPath: string): Promise<BrowserApiServiceResult<boolean>> {
    const result: Result = await window.adsk.fusionSendData('IsDocumentOpenInTheEditor', JSON.stringify({ documentPath }));
    return { value: result.content, errorMessage: result.errorMessage };
  }

  async runPublishChecks(): Promise<BrowserApiServiceResult<PublishChecks>> {
    const result: Result = await window.adsk.fusionSendData('RunPublishChecks');
    if (result.errorMessage) {
      return { value: null, errorMessage: result.errorMessage };
    }
    const publishChecks: PublishChecks = {
      isDocumentDirty: false,
      areRevitParametersDirty: false,
    };
    return { value: publishChecks, errorMessage: result.errorMessage };
  }

  /*
   * Needs implementation, not needed for MVP
   */

  async getDrawingFiles(topFolder: string): Promise<string[]> {
    console.log(topFolder);
    return [];
  }

  async logToFile(message: string, logLevel: LogLevel): Promise<void> {
    console.log(message, logLevel);
  }

  async readFromFileUsingDialog(
    fileName: string,
    fileLocation: string,
    filter: string,
    title: string,
    skipDialog: boolean,
  ): Promise<BrowserApiServiceResult<ReadFromFileUsingDialogResult>> {
    console.log(fileName, fileLocation, filter, title, skipDialog);
    return { value: null, errorMessage: 'not implemented' };
  }
  async saveToFileUsingDialog(
    content: string,
    fileName: string,
    fileLocation: string,
    filter: string,
    title: string,
    skipDialog: boolean,
  ): Promise<BrowserApiServiceResult<SaveToFileUsingDialogResult>> {
    console.log(fileName, fileLocation, filter, title, skipDialog);
    return { value: null, errorMessage: 'not implemented' };
  }

  /*
   * Inventor specific methods - Not needed for Fusion 360
   */

  async selectFolder(topFolder: string): Promise<string> {
    console.log(topFolder);
    return '';
  }

  async selectFile(topFolder: string, filter: string, multiSelect: boolean): Promise<string[]> {
    console.log(topFolder, filter, multiSelect);
    return [];
  }

  async extractZipFileToFolder(zipFile: string, targetFolderPath: string): Promise<BrowserApiServiceResult<string>> {
    console.log(zipFile, targetFolderPath);
    return { value: null, errorMessage: 'not implemented' };
  }

  /*
   * Revit specific methods - Not needed for Fusion 360
   */
  async updateRevitParameters(): Promise<void> {}

  async isEditDialogOpen(): Promise<BrowserApiServiceResult<boolean>> {
    return { value: false, errorMessage: null };
  }
  async editInstance(
    tenancyId: string,
    contentId: string,
    variantId: string,
    rfaSignedUrl: string,
    familyName: string,
    category: string,
    engineVersion: string,
    release: number,
    modelState: string,
    inputs: string,
    sourceFolderUrn: string,
    targetTenancyId: string,
  ): Promise<void> {
    console.log(
      tenancyId,
      contentId,
      variantId,
      rfaSignedUrl,
      familyName,
      category,
      engineVersion,
      release,
      modelState,
      inputs,
      sourceFolderUrn,
      targetTenancyId,
    );
  }

  async resizeWindow(width: number, height: number): Promise<void> {
    console.log(width, height);
  }

  async getSelectedRFAInstance(): Promise<string> {
    return '';
  }

  async getAllMIDInstancesData(): Promise<string> {
    return '';
  }

  async selectAndEditMidInstance(elementId: string): Promise<BrowserApiServiceResult<boolean>> {
    console.log(elementId);
    return { value: null, errorMessage: 'not implemented' };
  }

  async writeToPublishData(publishDataJson: string): Promise<void> {
    console.log(publishDataJson);
  }

  async downloadFileToLocalCache(
    key: string,
    signedUrl: string,
    name: string,
    type: string,
    metaDataJson: string | null,
  ): Promise<void> {
    console.log(key, signedUrl, name, type, metaDataJson);
  }

  async writeToCache(key: string, type: string, metaDataJson: string): Promise<void> {
    console.log(key, type, metaDataJson);
  }

  async insertRFA(
    tenancyId: string,
    contentId: string,
    variantId: string,
    rfaSignedUrl: string,
    familyName: string,
    category: string,
    engineVersion: string,
    release: number,
    modelState: string,
    inputs: string,
    sourceFolderUrn: string,
    targetTenancyId: string,
  ): Promise<void> {
    console.log(
      tenancyId,
      contentId,
      variantId,
      rfaSignedUrl,
      familyName,
      category,
      engineVersion,
      release,
      modelState,
      inputs,
      sourceFolderUrn,
      targetTenancyId,
    );
  }

  async downloadFileFromUrl(url: string): Promise<BrowserApiServiceResult<string>> {
    const result: Result = await window.chrome?.webview?.hostObjects.hostApi.DownloadFileFromUrl(url).sync();
    return { value: result.content, errorMessage: result.errorMessage };
  }

  async downloadFileFromUrlWithName(url: string, fileName: string): Promise<BrowserApiServiceResult<string>> {
    const result: Result = await window.chrome?.webview?.hostObjects.hostApi
      .DownloadFileFromUrlWithName(url, fileName)
      .sync();
    return { value: result.content, errorMessage: result.errorMessage };
  }

  async getLocallyCached(key: string): Promise<CachedFileInfo[]> {
    const resultString = await window.chrome?.webview?.hostObjects.hostApi.GetLocallyCached(key).sync();
    return JSON.parse(resultString);
  }
}

export default new BrowserApiService();
