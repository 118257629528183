import Blockly, { BlockSvg, FieldDropdown } from 'blockly';
import { blocklyInputsDropdown } from '../constants';
import { BlocklyState } from 'mid-types';
import {
  ADD_TAB_BLOCK_MUTATOR,
  CONTROL_BLOCK_MUTATOR,
  FORM_CONTAINER_BLOCK_MUTATOR,
  blocklyLabel,
  blocklyStatementInput,
} from './FormCodeblocks.constants';
import {
  dropdownHasInvalidInput,
  isCurrentSelectedOptionValid,
  isSelectedInputNotValid,
  removeWarningFromInputDropdown,
  disableBlockForUnadoptedInput,
  updateInputFieldDropdown,
} from '../utils';
import {
  getTabInputContainers,
  getStatementInputName,
  getTabNameFieldName,
  getTabNumberFieldName,
  getUniqueIdFromTabInputContainerName,
  createTabInputComponents,
} from './FormCodeblocks.utils';

if (!Blockly.Extensions.isRegistered(CONTROL_BLOCK_MUTATOR)) {
  Blockly.Extensions.registerMutator(CONTROL_BLOCK_MUTATOR, {
    saveExtraState(this: BlockSvg) {
      const inputsDropdown = this.getField(blocklyInputsDropdown) as FieldDropdown;
      const labelInput = this.getField(blocklyLabel);

      if (inputsDropdown && labelInput) {
        const inputsDropdownOptions = inputsDropdown.getOptions();
        const selectedOption = inputsDropdown.getText();
        //Check if the current value of the dropdown has a valid adopted parameter
        if (isCurrentSelectedOptionValid(selectedOption) && dropdownHasInvalidInput(inputsDropdownOptions)) {
          const updatedDropdown = removeWarningFromInputDropdown(this, inputsDropdownOptions);
          updateInputFieldDropdown(this, updatedDropdown, blocklyInputsDropdown);
        }

        return {
          inputsDropdown: inputsDropdown.getValue(),
          label: labelInput.getValue(),
          unadopted: !isCurrentSelectedOptionValid(selectedOption),
        };
      }
    },
    loadExtraState(this: BlockSvg, state: BlocklyState) {
      const inputsDropdown = this.getField(blocklyInputsDropdown) as FieldDropdown;
      const labelInput = this.getField(blocklyLabel);

      if (inputsDropdown && labelInput) {
        const inputsDropdownOptions = inputsDropdown.getOptions();
        if (isSelectedInputNotValid(inputsDropdownOptions, state.inputsDropdown)) {
          const dropdownWithUnadoptedInput = disableBlockForUnadoptedInput({
            block: this,
            inputName: state.inputsDropdown,
            inputValue: state.inputsDropdown,
            inputsDropdownOptions,
          });

          updateInputFieldDropdown(this, dropdownWithUnadoptedInput, blocklyInputsDropdown);

          // Get updated Inputs Dropdown and set value
          const latestInputsDropdown = this.getField(blocklyInputsDropdown);
          if (latestInputsDropdown) {
            latestInputsDropdown.setValue(state.inputsDropdown);
          }
        } else {
          inputsDropdown.setValue(state.inputsDropdown);
          labelInput.setValue(state.label);

          // if the parameter was unadopted, but now it is, the enabled state should be restored
          if (state.unadopted) {
            this.setEnabled(true);
          }
        }
      }
    },
  });
}

if (!Blockly.Extensions.isRegistered(FORM_CONTAINER_BLOCK_MUTATOR)) {
  Blockly.Extensions.registerMutator(FORM_CONTAINER_BLOCK_MUTATOR, {
    saveExtraState(this: BlockSvg) {
      const formNameField = this.getField(blocklyLabel);
      if (formNameField) {
        return {
          formName: formNameField.getValue(),
        };
      }
    },
    loadExtraState(this: BlockSvg, state: BlocklyState) {
      const formNameField = this.getField(blocklyLabel) as FieldDropdown;
      if (formNameField) {
        formNameField.setValue(state.formName);
      }
    },
  });
}

interface TabState {
  tabInputContainerName: string;
  tabNumberFieldName: string;
  tabNumberFieldValue: string;
  tabNameFieldName: string;
  tabNameFieldValue: string;
  statementInputName: string;
}

if (!Blockly.Extensions.isRegistered(ADD_TAB_BLOCK_MUTATOR)) {
  Blockly.Extensions.registerMutator(ADD_TAB_BLOCK_MUTATOR, {
    saveExtraState(this: BlockSvg) {
      const tabContainerInputs = getTabInputContainers(this.inputList);
      const tabsState: TabState[] = tabContainerInputs.map((input) => {
        const tabContainerUniqueId = getUniqueIdFromTabInputContainerName(input.name);
        if (!input.name.includes(tabContainerUniqueId)) {
          throw Error(
            `Tab container input name does not include unique ID retrieved. name: ${input.name} unique ID: ${tabContainerUniqueId}`,
          );
        }
        const statementInputName = getStatementInputName(tabContainerUniqueId);
        const tabNumberFieldName = getTabNumberFieldName(tabContainerUniqueId);
        const tabNameFieldName = getTabNameFieldName(tabContainerUniqueId);
        const tabNumberFieldValue: string = this.getFieldValue(tabNumberFieldName);
        const tabNameFieldValue = this.getFieldValue(tabNameFieldName);
        // Do not store the entire tabInput, as the block inputs have
        // circular references to the form container source block
        // and when we JSON.stringify the state value in the json generator, it breaks.
        // Blockly can understand and resolve cyclical references to the source block, but JSON.stringify cannot.
        return {
          tabInputContainerName: input.name,
          tabNumberFieldName,
          tabNumberFieldValue,
          tabNameFieldName,
          tabNameFieldValue,
          statementInputName,
        };
      });
      return {
        tabsState,
      };
    },
    loadExtraState(this: BlockSvg, state: BlocklyState) {
      if (state.tabsState && state.tabsState.length) {
        // The formContainerBlock's init() {}
        // command adds a blocklyStatementInput when the block initially loads.
        // we remove it here, so there isn't an extra statement
        // input when we restore the tabs state
        this.removeInput(blocklyStatementInput, true);

        const _createTabInputComponents = createTabInputComponents.bind(this);
        state.tabsState.forEach((tabState: TabState) => {
          _createTabInputComponents({
            tabInputContainerName: tabState.tabInputContainerName,
            tabNumberFieldName: tabState.tabNumberFieldName,
            tabNumberFieldValue: tabState.tabNumberFieldValue,
            tabNameFieldName: tabState.tabNameFieldName,
            tabNameFieldValue: tabState.tabNameFieldValue,
            tabStatementInputName: tabState.statementInputName,
          });
        });
      }
    },
  });
}
