import { useEffect, useState } from 'react';
import { generateUniversalInputsBlock } from '../../utils';
import { ProductDefinitionInputParameter } from 'mid-addin-lib';
import { isEmpty } from 'lodash';
import { removeQuotesFromTextParametersInArray } from 'mid-utils';
import { BlocklyState } from 'mid-types';
import { isFunctionAvailable, labelKey, readOnlyKey, valueKey, visibleKey } from '../InputCodeblocks.constants';

// Generates blocks for universal block based on data store
const useGenerateBlocklyState = (inputs: ProductDefinitionInputParameter[]): BlocklyState => {
  const [blocklyBlocks, setBlocklyBlocks] = useState<BlocklyState[]>([]);

  useEffect(() => {
    const inputsWithoutQuotes = removeQuotesFromTextParametersInArray(inputs);

    const generatedBlocks = inputsWithoutQuotes.reduce<BlocklyState[]>((acc, input) => {
      // We loop through each key and generate it's corresponding block when it's available
      // then we connect it to the previous block
      let parentBlock: BlocklyState = {};
      let currentBlock = parentBlock;
      Object.keys(input).forEach((key) => {
        //We exclude the value key because it generates conflict with the revit form
        if (isFunctionAvailable(key) && key !== valueKey) {
          const currentKey = key as keyof ProductDefinitionInputParameter;

          //Don't add block if property is default
          if ([visibleKey, readOnlyKey, labelKey].includes(key)) {
            if (
              (currentKey === visibleKey && input[currentKey] === true) ||
              (currentKey === readOnlyKey && input[currentKey] === false) ||
              (currentKey === labelKey && input[currentKey] === '')
            ) {
              return;
            }
          }

          //Don't add a block if value is undefined
          if (input[currentKey] === undefined) {
            return;
          }

          const newConnectingBlock = generateUniversalInputsBlock(
            // Code Blocks won't have deprecated types
            { name: input.name, type: input.type },
            key,
            input[currentKey],
          );

          if (currentBlock.next) {
            currentBlock.next = { block: { ...newConnectingBlock } };
            currentBlock = currentBlock.next.block;
          } else {
            parentBlock = { ...newConnectingBlock };
            currentBlock = parentBlock;
          }
        }
      });

      if (!isEmpty(parentBlock)) {
        acc.push(parentBlock);
      }
      return acc;
    }, []);
    setBlocklyBlocks(generatedBlocks);
  }, [inputs]);
  return {
    blocks: {
      languageVersion: 0,
      blocks: blocklyBlocks,
    },
  };
};

export default useGenerateBlocklyState;
